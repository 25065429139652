<template>
  <div>
    <Table stripe size="small" :max-height="680" :data="list" :columns="tableColumns" :loading="tableLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
        :current="query.pageNumber" @on-change="handleChangePage" :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'></Page>
    </div>
  </div>
</template>

<script>
import { getWxMessagePage } from '@/api/sys/website'
export default {
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: undefined,
        openId: undefined
      },
      list: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { title: '联系人', align: 'center', key: 'contracter' },
        { title: '联系电话', align: 'center', key: 'tel' },
        { title: '品牌', align: 'center', ellipsis: true, tooltip: true, key: 'brandName' },
        { title: '留言内容', align: 'center', ellipsis: true, tooltip: true, key: 'mark' },
        { title: '留言时间', align: 'center', key: 'createTime' }
      ]
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.token.userInfo
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    getTableData () {
      this.tableLoading = true
      this.query.publisherId = this.userInfo.publisherId
      getWxMessagePage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.getTableData()
    }
  }
}
</script>
